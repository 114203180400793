import LeatherGladstoneBagFront from "@public/nft-cards/TDCE24/0/front.png";
import CrowsNestBellFront from "@public/nft-cards/TDCE24/1/front.png";
import AmyBraceletFront from "@public/nft-cards/TDCE24/2/front.png";
import DinoChouest from "@public/nft-cards/TDCE24/3/front.png";
import DinoChouestMembersEdition from "@public/nft-cards/TDCE24/4/front.png";
import Image from "next/image";
import {useRouter} from "next/router";

import AppButton from "@/components/Button/AppButton";
import {clsMerge} from "@/utils/cls-merge";
import {EBGaramond} from "@/utils/font";


const digitalCollectibles1 = [
  LeatherGladstoneBagFront,
  CrowsNestBellFront,
  AmyBraceletFront,
]

const digitalCollectibles2 = [
  DinoChouest,
  DinoChouestMembersEdition,
]

const DigitalCollectiblesTab = () => {

  const router = useRouter();

  return (
    <div className={clsMerge("flex flex-col text-white p-6 md:p-10", EBGaramond.className)}>

      <h2
        className={clsMerge(
          "theme-h3 w-full text-center md:text-4xl md:font-semibold md:leading-normal",
          EBGaramond.className,
        )}
      >
        Expedition 2024 Digital Collectibles
      </h2>

      <div className="theme-body2 md:theme-body1 mt-6 font-normal text-center">
        Special Digital Collectibles from Expedition 2024 is out now. Claim yours today.
      </div>

      <div className="flex flex-col flex-wrap md:flex-row items-center justify-center w-full gap-4 mt-10">
        <div className="flex flex-row gap-4 justify-center items-center">
          {digitalCollectibles1.map((each, index) => (
            <div
              className="flex max-w-[95px] max-h-[95px] min-w-[95px] min-h-[95px] md:max-w-[160px] md:max-h-[160px]"
              key={`expedition-carousel-collectibles-${index}`}
            >
              <Image
                alt=""
                className="object-contain"
                src={each}
              />
            </div>
          ))}
        </div>
        <div className="flex flex-row gap-4 justify-center items-center">
          {digitalCollectibles2.map((each, index) => (
                <div
                  className="flex max-w-[95px] max-h-[95px] min-w-[95px] min-h-[95px] md:max-w-[160px] md:max-h-[160px]"
                  key={`expedition-carousel-collectibles-${index}`}
                >
                  <Image
                    alt=""
                    className="object-contain"
                    src={each}
                  />
                </div>
              ))}
        </div>
      </div>

      <div className="flex items-center justify-center my-10">
        <AppButton
          className="w-full md:w-auto"
          disabled={true}  // COLLECT NOW
          size="large"
          variant="contained"
          onClick={() => {
            router.push("/special-expedition-2024")
          }}>
          SOLD OUT
        </AppButton>
      </div>

    </div>
  )
}

export default DigitalCollectiblesTab;
